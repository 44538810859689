import StyledViewAllOffersLink from './StyledViewAllOffersLink';
import Link from '../../Atoms/Link/Link';
import { getOriginName } from '../../../utils/utility';
import { urls } from '../../../utils/constants';
const ViewAllOffersLink = () => {
    const shop = getOriginName() + urls.shop;
    return (
        <StyledViewAllOffersLink className="psc-cart-view-all-offers">
            <Link to={shop} config='anchorLink' size='sm' >
                view all plans
            </Link>
        </StyledViewAllOffersLink>
    );
};

export default ViewAllOffersLink;
