import StyledContainer from './EmptyCartContainerStyles';

const EmptyCartContainer = () => (
    <StyledContainer>
        <h3>Your Cart is empty</h3>
        <p>
            but we&apos;re full of solutions
        </p>
    </StyledContainer>
)

export default EmptyCartContainer;