import { useState, useEffect } from 'react';
import { renderCartApp } from '../../utils/constants';
import useOfferDetails from '../UseViewDetail/UseOfferDetail'
import useAddedOffer from '../UseAddedOffer/AddOfferDetail'
import useApiHandlers from './../UseApiHandlers/UseApiHandlers';

const useRenderApp = () => {
    const [showApp, setShowApp] = useState(false);
    const { getInitOffers } = useApiHandlers()
    const { clearViewedOffers } = useOfferDetails();
    const { clearAddedOffers } = useAddedOffer();
    const localFeatureSwitch = renderCartApp;
    const scriptTag = document.getElementById('revisit-customer')?.textContent;
    const featureKeyScript = document.getElementById('featureswitches')?.textContent;

    useEffect(() => {
        if (featureKeyScript) {
            const scriptTagProps = JSON.parse(featureKeyScript);

            if (localFeatureSwitch && scriptTagProps.EnablePortableShoppingCart) {
                setShowApp(true)
            }
        } else if (localFeatureSwitch) {
            setShowApp(true)
        }

        if (scriptTag) {
            getInitOffers();
            const customerTagProps = JSON.parse(scriptTag);

            // CHECK VERIFICATION OF CART, IF WE NEED TO CLEAR, CALL CLEAR FUNCTION
            if (!customerTagProps?.CustomerHasOfferInCart) {
                clearAddedOffers()
            }
            // CHECK VERIFICATION OF VIEWED, IF WE NEED TO CLEAR, CALL CLEAR FUNCTION
            if (!customerTagProps?.CustomerHasVisitedOffers) {
                clearViewedOffers()
            }
        }

    }, [])


    return { showApp } as const;
}

export default useRenderApp;