import StyledButton from "../../Atoms/Button/StyledButton";
import { handleCtaCopy } from "../OfferCard/OfferCardUtils";
import { FC } from "react";

interface AddOfferToCartButtonI {
    offer: any,
    isOfferInCart?: boolean | null | undefined,
    isCheckout?: boolean | false
}

const AddOfferToCartButton: FC<AddOfferToCartButtonI> = ({ offer, isOfferInCart, isCheckout }) => {
    const handleButton = () => {
        if (window.store?.dispatch) {
            window.store?.dispatch({
                type: 'offers/CHECK_LOCALIZATION',
                payload: offer.id
            })
        }
    };

    return (
        <StyledButton
            onClick={handleButton}
            config={isCheckout ? 'primary' : 'ghost'}
        >
            {handleCtaCopy(isOfferInCart, offer, isCheckout)}
        </StyledButton>
    );
};

export default AddOfferToCartButton;
