export const urls = {
    'shop': '/shop/offers',
    'mobile': '/learn/mobile',
    'prod': 'https://business.comcast.com',
    'cmbCart': '/shop/mobile/cart'
};

export const maxOffers = 4;
export const cookieName = 'cb_psc';
export const cookieExpires = 30;
export const headerClass = '#bcp-header';
export const navClass = '.bsd-nav-l2';
export const cartMountPoint = 'bsd-nav-cart-icon';
export const bseeAppTypes = ['BusinessServicesEcommerceExperience']
export const cbmAppTypes = ['ComcastBusinessMobileLearn', 'ComcastBusinessMobileBuy']
export const renderCartApp = true;


export const cookieNames = {
    SC: 'SC',
    visitorSessionId: 'RC.USID',
    SCMSID: 'SCMS_ID',
    extendedSession: 'EXTENDED_SESSION',
    BPO: 'BPOCookie'
};
