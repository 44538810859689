import BseeCart from './shopping-carts/bsee-cart/BseeCart';
import { PortableCartProvider } from './provider/CartProvider';
import StyledDynamicPortal from './components/Molecules/StyledCartDrawer/StyledDynamicPortal';
import CartButton from './components/Molecules/CartButton/CartButton';
import GlobalStyle from './globalStyles/GlobalStyles';
import CartContainer from './components/Containers/CartContainer/CartContainer';
import { useState, useEffect } from 'react';
import useRenderApp from './library/UseRenderApp/UseRenderApp';
import { appTypeShouldDisplay } from './utils/utility';
import { cbmAppTypes } from './utils/constants'

export const PortableShoppingCart = () => {
    const [appTypeState, setAppTypeState] = useState({
        applicationName: '',
        authenticated: false
    });
    const { showApp } = useRenderApp();
    useEffect(() => {
        if (window.__GlobalNav?.loaded) {
            const appType = window.__GlobalNav.applicationName;
            const authenticated = window.__GlobalNav.isAuthenticated;

            if (appType) {
                setAppTypeState({
                    applicationName: appType,
                    authenticated: authenticated
                });
            }
        } else {
            document.addEventListener('Global-Nav-Rendered', (evt) => {
                setAppTypeState({
                    applicationName: evt.detail.applicationName,
                    authenticated: evt.detail.authenticated
                });
            }, true)
        }

    }, []);

    if (!showApp || !appTypeShouldDisplay(appTypeState)) {
        return null
    }

    return (
        <PortableCartProvider>
            <GlobalStyle />
            <CartButton appName={appTypeState.applicationName} />
            {
                !cbmAppTypes.includes(appTypeState.applicationName) &&
                <StyledDynamicPortal id="psc" className="class">
                    <CartContainer>
                        <BseeCart />
                    </CartContainer>
                </StyledDynamicPortal>
            }
        </PortableCartProvider>
    );
};


export default PortableShoppingCart;
