import { useCallback, useEffect, useState } from 'react';
import { focusTrap } from './../../utils/utility';

const useShoppingCart = (defaultValue: boolean) => {
    const [isCartOpen, setIsCartOpen] = useState<boolean>(defaultValue);

    const toggleCart = useCallback(
        () => setIsCartOpen(!isCartOpen),
        [isCartOpen]
    );

    useEffect(() => {
        const root = document.documentElement;
        isCartOpen ? focusTrap(true, false) : focusTrap(true, true);

        return isCartOpen
            ? root.classList.add('psc-cart-open')
            : root.classList.remove('psc-cart-open');
    }, [isCartOpen]);

    return { isCartOpen, toggleCart } as const;
};

export default useShoppingCart;
