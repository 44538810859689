import EmptyCartContainer from "../../Molecules/EmptyCartContainer/EmptyCartContainer";
import StyledSection from "./AddedOfferStyles";
import CheckoutOfferSection from "../CheckoutOfferSection/CheckoutOfferSection";


const AddedOfferSection = (props: { addedOffer: any[] }) => (
    <StyledSection>
        {!props.addedOffer.length ? <EmptyCartContainer /> : <CheckoutOfferSection offer={props.addedOffer} />}
    </StyledSection>
)

export default AddedOfferSection;