import { useEffect, useContext } from 'react';
import BseeCartComponet from '../../components/Containers/BseeCartComponet/BseeCartComponent';
import { DBConfig } from './DBConfig';
import { initDB } from 'react-indexed-db';
import ctx from '../../provider/CartProvider';
import { updateCookieId } from './../../utils/utility';
import { cookieName } from './../../utils/constants';

initDB(DBConfig);


const BseeCart = () => {
    const { updateViewedItems, initViewedOffers, updateAddedOffers, initAddedOffers } = useContext(ctx);

    useEffect(() => {

        initViewedOffers();
        initAddedOffers();

        document.addEventListener('psc_viewed', (e) => {
            updateViewedItems(e.detail);
        }, true);

        document.addEventListener('localized_success', (e) => {
            updateCookieId(cookieName, e.detail)
        }, true);

        document.addEventListener('psc_added', (e) => {
            updateAddedOffers(e.detail);
        }, true);

        return () => {
            document.removeEventListener('psc_viewed', (e) => updateViewedItems(e.detail), true);
            document.removeEventListener('psc_added', (e) => updateAddedOffers(e.detail), true);
            document.removeEventListener('localized_success', (e) => {

                updateCookieId(cookieName, e.detail)
            }, true);
        };
    }, []);

    return <BseeCartComponet />;
};

export default BseeCart;
