import { FC, memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DynamicPortalI } from './DynamicPortalInterface';

const DynamicPortal: FC<DynamicPortalI> = ({ id, children, className }) => {
    const element = useRef(
        document.getElementById(id) || document.createElement('div')
    );
    const [dynamic] = useState(!element.current.parentElement);

    useEffect(() => {
        if (dynamic) {
            element.current.id = id;
            element.current.className = className;
            document.body.appendChild(element.current);
        }
        return () => {
            if (dynamic && element.current.parentElement) {
                element.current.parentElement.removeChild(element.current);
            } else {
                null;
            }
        };
    }, [id]);
    return createPortal(children, element.current);
};

export default memo(DynamicPortal);
