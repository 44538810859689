import styled from 'styled-components';
import DynamicPortal from '../../../library/DynamicPortal/CreateDynamicPortal';

const StyledDynamicPortal = styled(DynamicPortal)`
    display: block;
    font-size: 2rem;
    width: auto;
    background-color: var(--psc-white);
`;

export default StyledDynamicPortal;
