import { useContext, useState } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import { cookieFunction, getCookie } from '../../utils/utility';
import { CursorI } from '../UseViewDetail/interfaces';
import CartContext from "../../provider/CartProvider";
import { handleLogging } from "../../utils/logging";
import { cookieName } from './../../utils/constants';

const addOfferDetails = () => {
    const [addedOffers, setAddedOffers] = useState<string[]>([]);
    const { updateViewedItems } = useContext(CartContext)
    const checkViewewdOffer = async (offer: any) => {
        const { openCursor, deleteRecord } = useIndexedDB('psc_viewed');
        await openCursor(async evt => {
            const cursor: CursorI = evt?.target?.result;
            if (cursor) {
                if (cursor.value.offer.id === offer.id) {
                    await deleteRecord(cursor.value.timeStamp);
                } else {
                    cursor.continue();
                }
            }
        });
    };
    const updateAddedOffers = async (offer: { id: string }): Promise<void> => {
        const { openCursor, add, getAll, clear } = useIndexedDB("psc_added");

        const formattedOffer = {
            offer: offer,
            timeStamp: new Date().toISOString()
        };
        cookieFunction();
        await openCursor(async (evt) => {
            const cursor: CursorI = evt?.target?.result;
            if (cursor) {
                if (cursor.value.offer.id !== offer.id) {
                    await updateViewedItems(cursor.value.offer);
                } else {
                    cursor.continue();
                }
            }
        });
        await checkViewewdOffer(offer);
        await clear().then(async () => {
            await add(formattedOffer)
                .then(async () => {
                    await getAll()
                        .then((items) => {
                            handleLogging({
                                success: true,
                                message: 'success! offer added to cart'
                            });
                            return setAddedOffers(items);
                        })
                        .catch((e) => handleLogging(e));
                })
                .catch((e) => handleLogging(e));
        });
    };
    const initAddedOffers = async () => {
        const { getAll } = useIndexedDB('psc_added');
        const cartCookie = getCookie(cookieName)

        await getAll().then((items) => {

            if (cartCookie) {
                setAddedOffers(items);
            } else {
                clearAddedOffers();
                setAddedOffers([]);
            }

        }).catch((e) => handleLogging(e));

    };

    const clearAddedOffers = () => {
        const { clear } = useIndexedDB('psc_added');
        clear();
    }

    return { updateAddedOffers, initAddedOffers, addedOffers, clearAddedOffers } as const;
};
export default addOfferDetails;