
import { getVisitorIdFromCookie } from '../../utils/utility';
import { isLocalHost } from '../../utils/utility';

export const getCustomerInfo = () => {
    interface TestI {
        offers: {
            marketId: string | unknown
        },
        checkAvailability: {
            sessionId: string
        },
        credentialStore: {
            cimaEcom: {
                token: {
                    accessToken: string
                }
            }
        }
    }

    // SMALL CHECK TO ALLOW SB TESTING
    let storeData;
    if (!isLocalHost()) {
        storeData = window.store.getState() as TestI
    } else {
        storeData = window.parent.store as unknown as TestI
    }

    const cookieValue = getVisitorIdFromCookie();

    let store;
    if (storeData) {
        store = {
            marketId: storeData?.offers?.marketId,
            sessionId: storeData?.checkAvailability?.sessionId,
            token: storeData?.credentialStore?.cimaEcom?.token?.accessToken,
            cookieValue: cookieValue,
            isLocalized: storeData?.offers?.marketId ? false : true
        };
    }

    return store;
};