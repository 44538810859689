import { FC } from 'react';
import { LinkI } from './LinkInterface';
import StyledLink from './StyledLink';
const Link: FC<LinkI> = ({
    children,
    to,
    config = 'link',
    size,
    color = 'white'
}) => (<StyledLink href={to} config={config} size={size} color={color}>
    {children}
</StyledLink>);

export default Link;
