import styled from 'styled-components';
import { LinkStylesI } from './LinkInterface';

const StyledLink = styled.a<LinkStylesI>`
    background: ${props => props.config === 'button' && 'transparent' || props.config === 'link' && '#0d62ff' || 'none'};
    color: ${props => props.color === 'black' ? 'var(--psc-black)' : 'var(--psc-white)'};
    padding: ${props => props.config === 'button' && '10px 24px' || props.config === 'anchorLink' && '0' || props.config === 'link' && '10px 20px'};
    font-size: ${props => (props.size === 'sm' || props.config === 'button') ? '12px' : '16px'};
    text-transform: uppercase;
    text-align: ${props => props.config === 'anchorLink' ? 'left' : 'center'};
    letter-spacing: ${props => props.size == 'sm' && '1.5px'};
    font-family: 'Montserrat';
    cursor: pointer;
    text-decoration: ${props => props.config === 'anchorLink' && 'underline' || 'none'};
    font-weight: 600;
    width: auto;
    border-radius: 5px;
    border: ${props => props.config === 'button' ? 'solid 2px var(--psc-black)' : '0'};
    display: ${props => props.config === 'button' ? 'block' : 'inline'};

    &:hover,
    &:focus {
        color: ${props => props.config === 'button' && 'var(--psc-black)' || props.config === 'anchorLink' && 'var(--psc-blueMd)' || 'var(--psc-white)'};
        background: ${props => props.config === 'button' && 'var(--psc-grey)'};
    }

    &:focus {
        border-color: ${props => props.config === 'button' && 'var(--psc-blueMd)'};
    }
`;

export default StyledLink;
