import { getCustomerInfo } from '../../shopping-carts/bsee-cart/GetCustomerInfo';
import { getOriginName } from '../../utils/utility';
import { isLocalHost } from '../../utils/utility';
import { useIndexedDB } from 'react-indexed-db';

const useApiHandlers = () => {

    const setBaseUrl = () => {
        const route = getOriginName();
        let env;
        if (route.includes('qa') || isLocalHost) {
            env = '-qa';
        } else if (route.includes('stg')) {
            env = '-stg';
        } else {
            env = '';
        }
        return `https://ecom-api${env}.codebig2.net/offer-api/v4/viewed`;
    };

    const getInitOffers = async () => {
        const customer = getCustomerInfo();

        const url = customer?.isLocalized ?
            `${setBaseUrl()}/${customer?.cookieValue}/${customer?.sessionId}/offers` :
            `${setBaseUrl()}/${customer?.cookieValue}/geo/${customer?.marketId}/offers`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${customer?.token}`
            }

        });
        if (!response.ok) {
            return;

        }
        const res = await response.json();

        if (res) {
            clearAndUpdateViewedOffers(res.data)
        }
    };

    const clearAndUpdateViewedOffers = async (data: any[]) => {
        const { add, clear } = useIndexedDB('psc_viewed');

        await clear().then(() => {
            data.forEach(item => {
                add(item)
            })
        })
    }

    const reduceOffers = (offer: any[]) => {
        return offer.map(item => {
            return { offer: { id: item.offer.id }, timeStamp: item.timeStamp }
        })
    }

    const postViewedOffers = async (offers: any) => {
        const customer = getCustomerInfo();
        const url = `${setBaseUrl()}/offers`;

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${customer?.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'action': customer?.isLocalized ? 'Add' : 'Localized',
                'marketId': `${customer?.marketId}`,
                'sessionId': `${customer?.sessionId}`,
                'visitorId': `${customer?.cookieValue}`,
                'viewedOffers': reduceOffers(offers)
            })
        });
        if (!response.ok) {
            // prettier-ignore
            console.log(`%c MESSAGE`, 'background: #0047ab; color: #fff; padding: 3px;', response); // eslint-disable-line

        }
        const data = await response.json();

        if (data) {
            return data;
        }
    };

    return { getInitOffers, postViewedOffers } as const;
}

export default useApiHandlers;




