import styled from 'styled-components';

const StyledViewAllOffersLink = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 15px 0;
    margin: 0 20px;
    background-color: var(--psc-black);
    border-radius: 5px;
    a
{    text-decoration: none;
}

    @media(min-width: 481px) {
    background-color: #edefef;
    border-top: 2px solid rgba(204, 204, 204);
    border-radius: 0;
    margin: 0;
    a{
        color: var(--psc-black);
        text-decoration: underline;

    }

}
`;

export default StyledViewAllOffersLink;
