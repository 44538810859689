import { useState } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import { cookieFunction, getCookie } from '../../utils/utility';
import { CursorI } from './interfaces';
import useApiHandlers from './../UseApiHandlers/UseApiHandlers';
import { cookieName } from './../../utils/constants';


const useOfferDetails = () => {
    const [viewedOffers, setViewedOffers] = useState<string[]>([]);
    const { postViewedOffers } = useApiHandlers();

    const getAllViewedOffers = async () => {
        const { getAll } = useIndexedDB('psc_viewed');
        const allViewedOffers = await getAll().then(items => {
            return items;

        })

        return allViewedOffers
    }

    const handleAddItem = async (offer: { offer: { timeStampCheck: number, id: string }; timeStamp: string; }) => {
        const { add, getAll } = useIndexedDB('psc_viewed');

        await add(offer).then(async () => {
            await getAll().then((items) => {
                postViewedOffers(items)
                return setViewedOffers(items);
            });
        });
    }
    const updateViewedItems = async (offer: { timeStampCheck: number, id: string }): Promise<void> => {
        const { openCursor, deleteRecord } = useIndexedDB('psc_viewed');

        const formattedOffer = {
            offer: offer,
            timeStamp: new Date().toISOString()
        };
        cookieFunction();


        await openCursor(async evt => {
            const cursor: CursorI = evt?.target?.result;
            let confirm = false;
            if (cursor) {
                if (cursor.value.offer.id === offer.id) {
                    await deleteRecord(cursor.value.timeStamp).then(() => {
                        confirm = true;
                        handleAddItem(formattedOffer);
                    });
                } else {
                    cursor.continue();
                }
            } else {
                if (!confirm) {
                    handleAddItem(formattedOffer);
                }
            }
        });
    };

    const initViewedOffers = async () => {
        const cartCookie = getCookie(cookieName)
        const { getAll } = useIndexedDB('psc_viewed');

        await getAll().then((items) => {
            if (cartCookie) {
                setViewedOffers(items);
            } else {
                clearViewedOffers()
            }
        });
    };

    const clearViewedOffers = () => {
        const { clear } = useIndexedDB('psc_viewed');
        clear();
    }

    return { updateViewedItems, initViewedOffers, viewedOffers, clearViewedOffers, getAllViewedOffers } as const;
};

export default useOfferDetails;
