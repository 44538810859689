import styled from 'styled-components';

const StyledContainer = styled.div`
    padding: 22px 0;
    text-align: center;

    p {
        font-family: lato;
        font-size: 14px;
        color: var(--psc-greyDk);
        letter-spacing: 1px;
    }
`
export default StyledContainer;