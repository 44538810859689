import Link from '../../Atoms/Link/Link';
import StyledShopLinks from './ShopLinksStyles';
import { getOriginName } from '../../../utils/utility';
import { urls } from '../../../utils/constants';


const ShopLinks = () => {
    const shopLink = getOriginName() + urls.shop;
    const shopMobile = getOriginName() + urls.mobile;
    return (
        <StyledShopLinks>
            <Link to={shopLink} size='sm'>
                SHOP SERVICES
            </Link>
            <p>OR</p>
            <Link to={shopMobile} size='sm'>
                SHOP MOBILE FOR BUSINESS
            </Link>
        </StyledShopLinks>
    );
};

export default ShopLinks;
