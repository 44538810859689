import styled from 'styled-components';

const StyledOfferCard = styled.li`
    background-color: var(--psc-white);
    border: 1px solid rgb(229, 229, 229);
    padding: 24px;
    margin-top: 40px;
    font-size: 12px;
    font-family: lato;
    list-style: none;

    p {
        margin: 0;
        line-height: 1.5;
    }

    h3 {
        margin: 9px 0;

        a{
        text-transform: capitalize;
        font-weight: bold;
        }
    }

    ul {
        li {
            display: flex;
            align-items: center;
        }
    }

    .badge {
        font-family: Montserrat;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .name {
        &:hover {
            color: var(--psc-blueMd);
        }
    }

    .poi {
        &:after {
            display: inline-block;
            content: '';
            width: 6px;
            height: 6px;
            transform: rotate(135deg);
            border-right: 2px solid var(--psc-black);
            border-top: 2px solid var(--psc-black);
            margin-left: 6px;
            top: -2px;
            position: relative;
            transform-origin: center;
        }

        &:hover {
            color: var(--psc-blueMd);
            &:after {
                border-color: var(--psc-blueMd);
            }
        }

        &[aria-expanded='true'] {
            &:after {
                transform: rotate(-45deg);
                bottom: 6px;
                top: 1px;
            }
        }
    }

    button {
        margin-bottom: 10px;
    }

    .feature-list {
        margin:  0 0 20px 0;
        padding: 0;

        .feature-list-item {
            margin-bottom: 10px;
        }

        .feature-list-item-image {
            width: 32px;
            margin-right: 14.5px;
        }
    }

    .pricing-desc {
        margin: 0 0 20px;

        a {
            color: var(--psc-blueMd);
            text-decoration: underline;
            font-size: 12px;
            text-transform: inherit;
            font-family:lato;
            font-weight:normal;
        }
    }

    .rotate-icon {
        transform: rotate(180deg);
    }

    .psc-recently-viewed {
        margin: 0;
    }
`;

export default StyledOfferCard;
