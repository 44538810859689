import { useContext } from "react";
import OfferCard from '../../Molecules/OfferCard/OfferCard';
import ShopLinks from '../../Molecules/ShopLinks/ShopLinks';
import { sortOffers } from '../../../utils/utility';
import ViewAllOffersLink from '../../Molecules/ViewAllOffersLink/ViewAllOffersLink';
import CartContext from '../../../provider/CartProvider';
const ViewedOffersSection = (props: { offers: any[]; isOfferInCart: boolean | null | undefined }): JSX.Element => {
    const { addedOffers } = useContext(CartContext);
    const sortedOffers = sortOffers(props.offers);
    const renderOfferCards = () => (
        <ul>
            {
                sortedOffers.map((offer) => {
                    return (
                        <OfferCard offer={offer.offer} key={offer.offer.id} isOfferInCart={props.isOfferInCart} />
                    );
                })
            }
        </ul>
    );
    const shopLink = () => (addedOffers && !addedOffers.length) && (<ShopLinks />);
    return (
        <>
            {props.offers.length > 0 ? (
                <>
                    <section className="psc-cart-recently-viewed">
                        <h3>Recently Viewed</h3>
                        {renderOfferCards()}
                    </section>
                    <ViewAllOffersLink />
                </>
            ) : shopLink()}
        </>
    );
};
export default ViewedOffersSection;