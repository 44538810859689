export const DBConfig = {
    name: 'bsee-psc-db',
    version: 3,
    objectStoresMeta: [
        {
            store: 'psc_viewed',
            storeConfig: { keyPath: 'timeStamp', autoIncrement: true },
            storeSchema: [
                { name: 'offerId', keypath: 'id', options: { unique: true } }
            ]
        },
        {
            store: 'psc_added',
            storeConfig: { keyPath: 'timeStamp', autoIncrement: true },
            storeSchema: [
                { name: 'offerId', keypath: 'id', options: { unique: true } }
            ]
        }
    ]
};
