import { createContext, FC, ReactNode } from 'react';
import { PortableCartI } from './Interface';
import useShoppingCart from '../library/UseToggleCart/UseToggleCart';
import useOfferDetails from '../library/UseViewDetail/UseOfferDetail';
import addOfferDetails from '../library/UseAddedOffer/AddOfferDetail';

const PortableCartContext = createContext<PortableCartI>({
    isCartOpen: false,
    toggleCart() { },
    viewedOffers: [],
    updateViewedItems() { },
    initViewedOffers() { },
    addedOffers: null,
    updateAddedOffers() { },
    initAddedOffers() { }
});

export const PortableCartProvider: FC<ReactNode> = ({ children }) => {
    const { isCartOpen, toggleCart } = useShoppingCart(false);
    const { updateViewedItems, initViewedOffers, viewedOffers } =
        useOfferDetails();
    const { updateAddedOffers, initAddedOffers, addedOffers } =
        addOfferDetails();


    return (
        <PortableCartContext.Provider
            value={{
                isCartOpen,
                toggleCart,
                updateViewedItems,
                viewedOffers,
                initViewedOffers,
                updateAddedOffers,
                addedOffers,
                initAddedOffers
            }}
        >
            {children}
        </PortableCartContext.Provider>
    );
};

export default PortableCartContext;
