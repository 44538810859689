export const handleCtaCopy = (isOfferInCart: boolean | undefined | null, offer: any, isCheckout: boolean | undefined) => {

    if (isCheckout) {
        return 'CONTINUE TO CHECKOUT';
    };

    if (isOfferInCart) {
        const copy = offer?.isShellOffer ? 'GET A FREE QUOTE' : 'SWITCH TO THIS PLAN';

        return copy;
    } else {
        const copy = offer?.isShellOffer ? 'GET A FREE QUOTE' : 'ORDER NOW';

        return copy;
    }
};
