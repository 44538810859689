import { Key, useState, FC } from 'react';
import Button from '../../Atoms/Button/Button';
import { OfferCardI } from './OfferCardInterface';
import StyledOfferCard from './OfferCardStyles';
import { getOriginName, toUsdShorthand, setToHtml } from '../../../utils/utility';
import AddOfferToCartButton from '../AddOfferToCartButton/AddOfferToCartButton';
import { handleCtaCopy } from './OfferCardUtils';
import Link from '../../Atoms/Link/Link';

const OfferCard: FC<OfferCardI> = ({ offer, isOfferInCart, isCheckout }) => {
    const [showPoi, setShowPoi] = useState(false);

    return (
        <StyledOfferCard>
            <p className="badge" dangerouslySetInnerHTML={setToHtml(offer?.badgeText)} />
            <h3 className="name">
                <Link config='anchorLink' color='black' to={`${getOriginName()}/shop/offers/detail/${offer?.id}`}>{offer?.name}</Link>
            </h3>
            <Button classes='poi' onClick={() => setShowPoi(!showPoi)} isExpanded={showPoi} config="link" testId="poi-trigger">
                {offer?.isEPCEcoBillDiscountEnabled ? `$${offer?.epcEcoBillPrice}` : toUsdShorthand(offer?.price)}/mo
                <span className="price-per-month"> for {offer?.contractTerm} mos</span>
            </Button>
            {showPoi && <div className="pricing-desc">
                <p>
                    {offer?.contractDescription} {offer?.isEPCEcoBillDiscountEnabled && offer?.ecoBillOfferCardText} <Link config='anchorLink' to={`${getOriginName()}/shop/offers/detail/${offer.id}/#pricing`}>Pricing & Other Info</Link>
                </p>
            </div>}
            {/* <div className="cb-offer-card-price-disclaimer">with 2-year agreement <span className="cb-offer-card-price-disclaimer-description"></span><button className="text-link-secondary-medium" type="button" reference="[object Object]">Pricing &amp; Other Info</button></div> */}
            <ul className="feature-list">
                {offer?.offerCardCallouts?.map((callout: { offerCardCalloutIcon: { src: string | undefined; alt: string | undefined; }; offerCardCalloutMessage: any; offerCardCalloutTooltip: string | any[]; }, i: Key | null | undefined) => (
                    <li
                        className="feature-list-item"
                        key={i}
                    >
                        {callout.offerCardCalloutIcon &&
                            callout.offerCardCalloutIcon.src && (
                                <span className="feature-list-item-image">
                                    <img
                                        alt={''}
                                        src={getOriginName() + callout.offerCardCalloutIcon.src}
                                        width="30" />
                                </span>
                            )}
                        <span
                            className="feature-list-item-description"
                            dangerouslySetInnerHTML={setToHtml(callout.offerCardCalloutMessage)} />
                    </li>
                ))}
            </ul>
            <div className="calls-to-action">
                {offer?.isShellOffer ?
                    <Link config='button' color='black' to={`#`}>{handleCtaCopy(isOfferInCart, offer, isCheckout)}</Link>
                    : <AddOfferToCartButton isOfferInCart={isOfferInCart} offer={offer} isCheckout={isCheckout} />
                }
            </div>
        </StyledOfferCard >
    );
};
export default OfferCard;
