import { FC, useContext, useState, useRef, useEffect } from 'react';
import CartPreviewStyles from './CartContainerStyles';
import CartContext from '../../../provider/CartProvider';
import { cartPosition } from '../../../utils/utility';
import CartHeader from '../../Molecules/CartHeader/CartHeader';
const CartContainer: FC = ({ children }) => {
    const { isCartOpen, toggleCart } = useContext(CartContext);
    const [topHeight, setTopHeight] = useState(0);
    const cartRef = useRef<HTMLElement>(null);
    const cart = cartRef.current;
    const cartPost = (direction: string) => {
        if (cart) {
            if (isCartOpen && direction === 'end') {
                cart.style.position = 'absolute';
                return;
            } else if (!isCartOpen && direction === 'start' || !isCartOpen && direction === 'end') {
                cart.style.position = 'fixed';
                return;
            }
        }
    };
    const handleOpenCart = (evt: any) => {
        if (cartRef && !cartRef?.current?.contains(evt.target)) {
            toggleCart()
        }
    }

    useEffect(() => {
        const top = cartPosition();
        if (top) {
            setTopHeight(top);
        }
        cart?.addEventListener('transitionend', () => { cartPost('end'); }, true);
        cart?.addEventListener('transitionstart', () => { cartPost('start'); }, true);
        if (isCartOpen === true) {
            document.addEventListener('click', handleOpenCart);
        }

        return () => {
            cart?.removeEventListener('transitionend', () => { cartPost('end'); }, true);
            cart?.removeEventListener('transitionstart', () => { cartPost('start'); }, true);
            document.removeEventListener('click', handleOpenCart);
        };
    }, [isCartOpen]);

    return (
        <CartPreviewStyles aria-hidden={!isCartOpen} topHeight={topHeight} ref={cartRef} role="dialog">
            <CartHeader />
            {children}
        </CartPreviewStyles >
    );
};

export default CartContainer;
